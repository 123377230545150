@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,500&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Ubuntu+Condensed&display=swap");
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;1,500&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,500&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Ubuntu+Condensed&display=swap");
body {
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background: #a1a1a1;
  border-radius: 24px;
}

.test {
  filter: dropShadow(0 5px 3px rgba(100, 0, 200, 0.5));
  clip-path: polygon(0% 0%, 0% 60%, 50% 100%, 100% 60%, 100% 0%);
}
// .slick_center{
//   background-color: red;
//   height: 275px;
// }

.scroll_up {
  &.visible {
    opacity: 1; // Make it visible
    animation: fadeUp 0.5s ease-in-out;
  }
  &.hidden {
    opacity: 0; // Make it visible
    z-index: -1;
    animation: fadeUpOut 0.5s ease-in-out;
  }
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeUpOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}
